import { atom } from 'recoil';

import { PATIENT_CARD_SIDE_PANEL_CONFIG } from 'constants/patientCard';
import { AutoSuggestions } from 'types/autoSuggest.types';
import {
  GiveInstructionsFilterValues,
  PatientFilterValues,
  PatientListSort,
  SidepanelPreference,
  TreatmentFamilies
} from 'types/PatientList.types';

import {
  ATOMS,
  defaultGiveIntructionsListFilters,
  defaultPatientFamilies,
  defaultPatientListFilters,
  defaultPatientListSelectedTab,
  defaultPatientListSort,
  patientListSearchDefaultValue
} from './constants';

export const checkNewBracesTemplatizedPrescriptionEnabled = atom<boolean>({
  key: ATOMS.TEMPLATIZED_PRESCRIPTION,
  default: false
});
export const checkNewRefinementTemplatizedPrescriptionEnabled = atom<boolean>({
  key: ATOMS.REFINEMENT_TEMPLATIZED_PRESCRIPTION,
  default: false
});
export const patientListFilters = atom<PatientFilterValues>({
  key: ATOMS.PATIENT_LIST_FILTER_ATOM,
  default: defaultPatientListFilters
});
export const giveInstructionsListFilters = atom<GiveInstructionsFilterValues>({
  key: ATOMS.GIVE_INSTRUCTIONS_LIST_FILTER_ATOM,
  default: defaultGiveIntructionsListFilters
});

export const patientListSearchText = atom<AutoSuggestions | undefined>({
  key: ATOMS.PATIENT_LIST_SEARH_TEXT_ATOM,
  default: patientListSearchDefaultValue
});

export const giveInstructionsListSearchText = atom<AutoSuggestions | undefined>(
  {
    key: ATOMS.GIVE_INSTRUCTIONS_LIST_SEARCH_TEXT_ATOM,
    default: undefined
  }
);
export const patientListSelectedTab = atom<string>({
  key: ATOMS.PATIENT_LIST_SELECTED_TAB,
  default: defaultPatientListSelectedTab
});
export const patientListSort = atom<PatientListSort>({
  key: ATOMS.PATIENT_LIST_SORT,
  default: defaultPatientListSort
});

export const patientSidepanelPreference = atom<
  Record<string, SidepanelPreference>
>({
  key: ATOMS.PATIENT_SIDEPANEL_PREFERENCE,
  default: {}
});
export const sideDrawerPreference = atom<SidepanelPreference>({
  key: ATOMS.PATIENT_SIDEDRAWER_PREFERENCE,
  default: {
    view: PATIENT_CARD_SIDE_PANEL_CONFIG.INBOX.key,
    isOpen: false
  }
});

export const patientFamilies = atom<TreatmentFamilies>({
  key: ATOMS.PATIENT_FAMILIES,
  default: defaultPatientFamilies
});

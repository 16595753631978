import { gql } from '@apollo/client';

export const GET_ACTIVE_TREATMENT = gql`
  query getActiveTreatment($id: ID!) {
    getActiveTreatmentsForPatient(patientId: $id) {
      id
      employee {
        id
        firstName
        lastName
      }
      medicalStaff {
        nurses {
          details {
            id
            firstName
            lastName
          }
        }
        doctors {
          details {
            id
            firstName
            lastName
          }
        }
      }
    }
  }
`;

export const GET_APPOINTMENTS = gql`
  query getAppointments($input: FilterAppointmentInput) {
    getAppointments(input: $input) {
      id
      title
      location
      description
      status
      expireAt
      wowEnabled
      isClosed
      treatmentAppointmentId
      treatment {
        id
        details {
          opportunityId
        }
        type
        stage
        status
      }
      participants {
        user {
          id
          firstName
          lastName
          phone
          ... on Patient {
            ageCategory
          }
        }
        role
      }
      startTime
      statusInfo {
        statusCode
      }
      endTime
      type {
        id
        name
      }
      cpAppointmentId
    }
  }
`;

export const CREATE_APPOINTMENT = gql`
  mutation createAppointment($input: CreateAppointmentInput!) {
    createAppointment(input: $input) {
      id
    }
  }
`;

export const UPDATE_APPOINTMENT = gql`
  mutation updateAppointment($id: ID!, $input: UpdateAppointmentInput!) {
    updateAppointment(id: $id, input: $input) {
      id
    }
  }
`;

export const GET_APPOINTMENT_TYPES = gql`
  query getAppointmentTypes {
    getAppointmentTypes {
      id
      name
    }
  }
`;

export const DELETE_APPOINTMENT = gql`
  mutation deleteAppointment($id: ID!, $is_retainer_appointment: Boolean) {
    deleteAppointment(
      id: $id
      is_retainer_appointment: $is_retainer_appointment
    ) {
      title
    }
  }
`;

export const GET_ALL_APPOINTMENTS = gql`
  query getAllAppointments($filter: AppointmentFilter)
  @api(name: treatmentMonitor) {
    getAllAppointments(filter: $filter) {
      appointmentId: appointment_id
      title
      participants {
        id
        name
        role
        salesforceId
      }
      doctorClinic: doctor_clinic
      startTime: start_time
      endTime: end_time
      appointmentTypeLabel: appointment_type_label
      statusLabel: status_label
      treatmentId: treatment_id
      treatmentType: treatment_type
      opportunityId: opportunity_id
      appointmentStatus: appointment_status
      wowEnabled: wow_enabled
      isClosed: is_closed
      treatmentAppointmentId: treatment_appointment_id
      expireAt: expire_at
      clinciSfId: clinic_sf_id
      doctorSfId: doctor_sf_id
      description
      isRescheduled: is_rescheduled
      appointmentAction: appointment_action
      expectedDate: expected_appointment_date
      actualDate: actual_appointment_date
      appointment_outcome: appointment_outcome
    }
  }
`;

export const GET_TREATMENT_BY_CP_ID = gql`
  query getTreatmentByCpIdForCalendar($cpId: ID!, $opportunityId: String) {
    getTreatmentByCpId(cpId: $cpId, opportunityId: $opportunityId) {
      id
      type
      opportunityId
      stage
      patientId
      medicalStaff {
        doctors {
          details {
            id
          }
          primary
          role
        }
      }
      patient {
        email
        phone
        primaryCountry
        details {
          ageCategory
        }
      }
    }
  }
`;

export const GET_TREATMENT_BY_ID = gql`
  query getTreatmentByIdForCalendar($id: ID!) {
    getTreatmentById(id: $id) {
      id
      type
      opportunityId
      stage
      patientId
      patient {
        email
        phone
        primaryCountry
        details {
          ageCategory
        }
      }
      medicalStaff {
        doctors {
          details {
            id
          }
          primary
          role
        }
      }
    }
  }
`;

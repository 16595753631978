import {
  ApolloCache,
  DefaultContext,
  LazyQueryHookOptions,
  MutationHookOptions,
  MutationTuple,
  OperationVariables,
  QueryHookOptions,
  QueryResult,
  QueryTuple
} from '@apollo/client';

import { Option, SlotOption } from 'types/select';

export interface Comments {
  noteId: string;
  description: string;
}

export interface AfterSalesConfirmationFormInput {
  advanceNoticePeriod: number;
}

export interface AfterSalesAppointment {
  patientName: string;
  doctorClinic: string;
  opportunityType: string;
  doctorName: string;
  doctorSfId: string;
  clinicSfId: string;
  country?: string;
  appointmentType: string;
  expectedAppointmentDate: string;
  actualAppointmentDate: string;
  treatmentId: string;
  treatmentType: string;
  appointmentStatus: string;
  appointmentId: string;
  appointmentAction: string;
  comments: Comments[];
  startTime: Date;
  endTime: Date;
  createdReason: string;
  advanceNoticePeriod: string;
  setNumber: string;
  appointmentOrder: string;
  enabled: boolean;
  opportunityId: string;
  rescheduledReason: string;
  appointment_outcome: string;
}

export interface ScheduleFormInput {
  clinic: Option;
  doctor: Option;
  slot: SlotOption;
  appointmentStatus?: Option;
  onlyDateSelected?: boolean;
}

export interface AdditionalInfoFormInput {
  advanceNoticePeriod: number;
  comments: string;
  reasonForAppointment: string;
  appointmentOrder?: Option;
  set: Option;
  expectedAppDate: Date | string;
}

export type AdditionalInfo = AdditionalInfoFormInput & {
  rescheduledReason: { value: string };
};

export enum AppointmentStatus {
  TO_BE_SCHEDULED = 'TO_BE_SCHEDULED',
  BOOKED = 'BOOKED',
  CONFIRMED = 'CONFIRMED',
  RESCHEDULED = 'RESCHEDULED',
  SHOW_UP = 'SHOW_UP',
  NO_SHOW = 'NO_SHOW',
  CANCELLED = 'CANCELLED'
}

export enum AppointmentOrder {
  BEFORE_ALIGNER = 'BEFORE_ALIGNER',
  AFTER_ALIGNER = 'AFTER_ALIGNER'
}

interface DoctorInfo {
  email: string;
  first_name: string;
  last_name: string;
  country_id: string;
  phone_number: string;
}

export interface EditAppointmentInput {
  doctor_sf_id?: string;
  clinic_sf_id?: string;
  appointment_status?: AppointmentStatus;
  enabled?: boolean;
  advance_notice_period?: number;
  actual_appointment_date?: Date;
  start_time?: Date;
  end_time?: Date;
  created_reason?: string;
  appointment_comment?: string;
  before_aligner_number?: number;
  expected_appointment_date?: Date;
  appointment_order?: AppointmentOrder;
  clinic_name?: string;
  doctor_info?: DoctorInfo;
  is_retainer_appointment?: boolean;
}

export interface EditAppointmentDetails {
  id: string;
  input: EditAppointmentInput;
}
export interface DeleteAppointmentDetails {
  id: string;
}
export interface ExportAppointments {
  message: string;
}
export interface ExportAppointmentDetails {
  exportAppointments: ExportAppointments;
}

export interface CreateAppointmentInput {
  doctor_sf_id: string;
  clinic_sf_id: string;
  before_aligner_number: number;
  appointment_type: string;
  created_reason: string;
  appointment_order: string;
  comments: string;
  advance_notice_period: number;
  actual_date?: string;
  expected_date: string;
  start_time?: string;
  end_time?: string;
}

export interface CreateAppointment {
  createAppointment: AfterSalesAppointment;
}
export interface EditAppointmentConfig {
  (config?: MutationHookOptions): MutationTuple<
    EditAppointmentDetails,
    OperationVariables,
    DefaultContext,
    ApolloCache<any>
  >;
}
export interface DeleteAppointment {
  (config?: MutationHookOptions): MutationTuple<
    DeleteAppointmentDetails,
    OperationVariables,
    DefaultContext,
    ApolloCache<any>
  >;
}
export interface ExportAppointment {
  (config?: MutationHookOptions): MutationTuple<
    ExportAppointmentDetails,
    OperationVariables,
    DefaultContext,
    ApolloCache<any>
  >;
}
export interface CreateAppointmentConfig {
  (config?: MutationHookOptions): MutationTuple<CreateAppointment, any>;
}

export interface AfterSalesAppointments {
  totalCount: number;
  appointments: AfterSalesAppointment[];
}
export interface GetAfterSalesAppointments {
  getAllAfterSalesAppointments: AfterSalesAppointments;
}
export interface GetAllAfterSalesAppointments {
  (config?: QueryHookOptions): QueryResult<GetAfterSalesAppointments>;
}

export interface GetAfterSalesForOpportunities {
  getAllAppointmentForOpportunities: AfterSalesAppointment[];
}
export interface GetAllAppointmentsForOpportunities {
  (config?: QueryHookOptions): QueryResult<GetAfterSalesForOpportunities>;
}
export interface AppointmentActionsCount {
  appointmentAction: string;
  count: number;
}

export interface AppointmentTypesCount {
  appointmentType: string;
  count: number;
}

export interface AllAppointmentActionsCount {
  getAllAppointmentActionsCount: AppointmentActionsCount[];
}

export interface AllAppointmentTypeCount {
  getAppointmentTypeCount: AppointmentTypesCount[];
}
export interface GetAllAppointmentActionsCount {
  (config?: QueryHookOptions): QueryResult<AllAppointmentActionsCount>;
}

export interface GetAllAppointmentTypesCount {
  (config?: QueryHookOptions): QueryResult<AllAppointmentTypeCount>;
}
export interface ExpectedAppointmentDate {
  getExpectedAppointmentDate: {
    expectedDate: string;
  };
}
export interface GetExpectedAppointmentDate {
  (config?: LazyQueryHookOptions<ExpectedAppointmentDate>): QueryTuple<
    ExpectedAppointmentDate,
    OperationVariables
  >;
}
export interface SfIdTreatment {
  treatment: {
    id: string;
    no_of_aligner: number;
  };
}
export interface TreatmentBySfIdDetails {
  getTreatmentBySfId: SfIdTreatment;
}
export interface GetTreatmentBySfId {
  (config?: QueryHookOptions): QueryResult<TreatmentBySfIdDetails>;
}

export interface TimeSlot {
  from: Date;
  to: Date;
}
export interface AfterSalesAppointmentDetails {
  patient_preferred_slots_date_time: TimeSlot[];
  patientName: string;
  appointmentType: string;
  appointmentOrder: string;
  alignerSetNo: number;
  appointmentStatus: string;
  treatmentId: string;
  doctorClinic: string;
  doctorName: string;
  actualAppointmentDate: string;
  startTime: string;
  endTime: string;
  defaultDuration: number;
  appointmentStatusLabel: string;
  enabled: boolean;
  createdReason: string;
  advanceNoticePeriod: number;
  expectedAppointmentDate: string;
  comments: Comments[];
  doctorSfId: string;
  appointmentAction: string;
  clinicSfId: string;
  appointmentId: string;
  opportunityId: string;
  opportunityType: string;
  country: string;
  rescheduledReason: string;
  appointment_outcome: string;
  treatmentType: string;
}

interface DoctorAppointmentPreference {
  code: string;
  duration: number;
}
export interface AfterSalesAppointmentByCpId {
  getAppointmentDetailsByCpId: AfterSalesAppointmentDetails;
}
export interface GetAfterSalesAppointmentBySfId {
  (config?: LazyQueryHookOptions<AfterSalesAppointmentByCpId>): QueryTuple<
    AfterSalesAppointmentByCpId,
    OperationVariables
  >;
}
export interface AvailableHoursDetails {
  startTime: string;
  endTime: string;
}
export interface DoctorTimingsDetails {
  availableHours: AvailableHoursDetails[];
  day: string;
}
export interface TreatmentPatientDetails {
  email: string;
  phone: string;
  primaryCountry: string;
}
export interface TreatmentByCpIdDetails {
  id: string;
  patientId: string;
  patient: TreatmentPatientDetails;
  doctorTimings: DoctorTimingsDetails[];
  doctorAppointmentPreference?: DoctorAppointmentPreference[];
}
export interface TreatmentByCpId {
  getTreatmentByCpId: TreatmentByCpIdDetails;
}
export interface GetTreatmentByCPID {
  (config?: LazyQueryHookOptions<TreatmentByCpId>): QueryTuple<
    TreatmentByCpId,
    OperationVariables
  >;
}
export interface Timings {
  doctorTimings: AvailableHoursDetails[];
  label: string;
}
export interface DaysAndTimings {
  [key: string]: Timings;
}

export interface AddAppointmentDefaultProps {
  treatment: Option;
  clinic: Option;
  advanceNoticePeriod: number;
  doctor: Option;
  onlyDateSelected: boolean;
}

export interface ScheduleInput {
  appointment_status: string | undefined;
  start_time?: string | Date | undefined;
  end_time?: string | Date | undefined;
  clinic_sf_id?: string | undefined;
  clinic_name?: string | undefined;
  doctor_sf_id?: string | undefined;
  doctor_info?: any;
}

import { FC, TextareaHTMLAttributes } from 'react';

import classNames from 'classnames';
import { Controller, useFormContext } from 'react-hook-form';

import AnimateLabel from 'components/AnimateLabel/AnimateLabel';
import AutoTextArea from 'components/AutoTextarea/AutoTextarea';
import { FormError } from 'components/FormError/FormError';
import { If } from 'components/Generics';
import { useFieldRefContext } from 'contexts/filesRefs.context';
import { customTwMerge as twMerge } from 'utils';

interface Props {
  name: string;
  autogrow?: boolean;
  showError?: boolean;
  label?: string;
  className?: string;
  fontClass?: string;
  initialHeight?: string;
  inputProps?: TextareaHTMLAttributes<HTMLTextAreaElement>;
  maxgrowHeight?: number;
  title?: string;
  animateLabel?: string;
  labelclassName?: string;
}

export const RHFTextArea: FC<Props> = ({
  name,
  inputProps,
  autogrow = false,
  showError = true,
  className,
  initialHeight,
  fontClass,
  maxgrowHeight,
  title = '',
  animateLabel,
  labelclassName = 'text-17px mb-6'
}) => {
  const { animatedField, callback } = useFieldRefContext();
  const { control } = useFormContext();
  return (
    <Controller
      name={name}
      render={({ field, fieldState: { error } }) => {
        return (
          <div ref={callback?.(name)} className='relative flex flex-col'>
            <If condition={!!title}>
              <div
                className={twMerge(
                  classNames({
                    'text-ERROR': error
                  }),
                  labelclassName
                )}
              >
                <label>{title}</label>
                <AnimateLabel
                  animate={animatedField === animateLabel}
                  className='text-ERROR text-collapsible font-normal'
                >
                  {title}
                </AnimateLabel>
              </div>
            </If>
            {!autogrow ? (
              <>
                <textarea
                  className={twMerge(
                    'bg-GRAY px-2 py-1 w-full h-full outline-none ',
                    className
                  )}
                  {...field}
                  {...inputProps}
                />
                {showError && <FormError errorMessage={error?.message} />}
              </>
            ) : (
              <>
                <AutoTextArea
                  {...inputProps}
                  onChange={field.onChange}
                  value={field.value}
                  onBlur={field.onBlur}
                  className={className}
                  fontClass={fontClass}
                  initialHeight={initialHeight}
                  maxgrowHeight={maxgrowHeight}
                />
              </>
            )}
          </div>
        );
      }}
      control={control}
    />
  );
};

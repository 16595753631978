import { gql } from '@apollo/client';

import { CORE_TICKET_FIELDS } from './InTreatment.queries';

export const GET_TREATMENT_PLANS = gql`
  query getTreatmentPlans($treatmentId: String, $rejectedBy: [TPVRejectedBy!]) {
    getTreatmentPlans(
      params: { treatmentId: $treatmentId }
      paginationInfo: { offset: 0, limit: 10 }
    ) {
      edges {
        id
        name
        type
        patient {
          firstName
          lastName
        }
        versions(
          input: {
            filter: { rejectedBy: $rejectedBy }
            sort: { sortBy: "createdAt", sortOrder: DESC }
          }
        ) {
          createdAt
          updatedAt
          id
          type
          name
          url3DViewer
          doctor3dViewerUrl
          documents {
            presignedUrl
            documentType
          }
          rejectionInfo {
            rejectedBy
            reason
            isPublic
            translatedReason
          }
          ipr
          attachments
          acceptanceStatus
          numberOfZenStages
          numberOfSets
          teethToExtract
          riskAssessmentForAppt
          patientComments
          zenyumCouncilComments
          treatingDoctorComments
          translatedZenyumCouncilComments
          translatedTreatingDoctorComments
          url3DSimulationVideo
          urlSmileSummary
          teethExtractionZenplus
          confirmedTeethExtractions
          accessToken3dViewer
          acceptanceStatusUpdatedAt
          details {
            designSpecification {
              isPlanNeeded
              category {
                type
              }
            }
            metadata
            prescription {
              specialNotes
            }
            resimParentId
            resimChildIds
          }
          treatmentPlanningLab
        }
        status
        treatment {
          status
          stage
        }
      }
    }
  }
`;

export const UPDATE_TREATMENT_PLAN = gql`
  mutation ($id: ID!, $input: UpdateTreatmentPlanInput) {
    updateTreatmentPlan(treatmentPlanId: $id, input: $input) {
      id
    }
  }
`;

export const UPDATE_TREATMENT_PLAN_VERSION = gql`
  mutation ($id: ID!, $input: TreatmentPlanVersionUpdateInput!) {
    updateTreatmentPlanVersion(treatmentPlanVersionId: $id, input: $input) {
      id
    }
  }
`;
export const REOPEN_TREATMENT_PLAN_VERSION = gql`
  mutation ($input: ReOpenTreatmentPlanVersionInput!) {
    reOpenTreatmentPlanVersion(input: $input) {
      id
    }
  }
`;
export const RAISE_CASEOPS_ISSUE = gql`
  mutation ($input: RaiseCaseOpsIssueInput) {
    raiseCaseOpsIssue(input: $input) {
      id
    }
  }
`;

export const REVIEW_TPV_UPDATE = gql`
  ${CORE_TICKET_FIELDS}
  mutation ($id: ID!, $input: ReviewTPVUpdateInput!) {
    reviewTreatmentPlanVersion(treatmentPlanVersionId: $id, input: $input) {
      ticketResponse {
        ...CoreTicketFields
      }
    }
  }
`;

export const GET_TICKETS = gql`
  query GetTicketByTopicAndContextInfo($input: TicketByTopicAndContextInput) {
    getTicketByTopicAndContextInfo(input: $input) {
      id
      topicId
    }
  }
`;

export const REASSIGN_COUNCIL = gql`
  mutation ReassignCouncil($treatmentId: String!, $doctorId: String!) {
    reAssignCouncil(treatmentId: $treatmentId, doctorId: $doctorId) {
      details {
        firstName
        lastName
      }
      role
    }
  }
`;

import { BookedIcon, CancelledIcon, ErrorCrossIcon } from 'assets/icons';
import { DaysAndTimings } from 'types/AfterSalesAppointment.types';
import { AfterSalesAppointmentActionBtns } from 'types/Appointment.types';
import { Option } from 'types/Referrals.types';
import { translate } from 'utils';

const APPOINTMENT_TABS = {
  incoming: 'incoming',
  pending: 'pending',
  confirmed: 'confirmed',
  preSalesAppointments: 'preSales',
  afterSalesAppointments: 'afterSales'
};

export const RESCHEDULE_REASON_LABEL: Record<string, string> = {
  UNCONFIRMED: 'rescheduled_reason.unconfirmed',
  NO_SHOW: 'rescheduled_reason.no_show',
  NA: 'rescheduled_reason.not_applicable',
  DOCTOR_ON_LEAVE: 'rescheduled_reason.doctor_leave'
};

export const CONFIRMED_APPOINTMENTS_TABLE_LIST_LENGTH = 20;

const APPOINTMENT_TAB_OPTIONS = [
  { id: APPOINTMENT_TABS.incoming, label: 'Incoming appointment requests' },
  { id: APPOINTMENT_TABS.pending, label: 'Pending patient confirmation' },
  { id: APPOINTMENT_TABS.confirmed, label: 'Confirmed appointments' }
];

export const DAYS_AND_TIMINGS: DaysAndTimings = {
  MONDAY: {
    label: translate('edit_appointment.monday'),
    doctorTimings: []
  },
  TUESDAY: {
    label: translate('edit_appointment.tuesday'),
    doctorTimings: []
  },
  WEDNESDAY: {
    label: translate('edit_appointment.wednesday'),
    doctorTimings: []
  },
  THURSDAY: {
    label: translate('edit_appointment.thursday'),
    doctorTimings: []
  },
  FRIDAY: {
    label: translate('edit_appointment.friday'),
    doctorTimings: []
  },
  SATURDAY: {
    label: translate('edit_appointment.saturday'),
    doctorTimings: []
  },
  SUNDAY: {
    label: translate('edit_appointment.sunday'),
    doctorTimings: []
  }
};

export const APPOINTMENT_STATUS_OPTIONS: Record<string, Option> = {
  BOOKED: { label: 'Booked', value: 'BOOKED' },
  SHOW_UP: { label: 'Showed up', value: 'SHOW_UP' },
  CANCELLED: { label: 'Cancelled', value: 'CANCELLED' },
  NO_SHOW: { label: 'No show', value: 'NO_SHOW' },
  RESCHEDULED: { label: 'Rescheduled', value: 'RESCHEDULED' },
  CLOSED_LOST: { label: 'Closed lost', value: 'CLOSED_LOST' },
  PENDING_PREFERRED_SLOTS_FROM_CUSTOMER: {
    label: 'Pending preferred slots from customer',
    value: 'PENDING_PREFERRED_SLOTS_FROM_CUSTOMER'
  },
  PENDING_CALL_WITH_CS: {
    label: 'Pending call with CS',
    value: 'PENDING_CALL_WITH_CS'
  },
  PENDING_CLINIC_INPUT: {
    label: 'Pending clinic input',
    value: 'PENDING_CLINIC_INPUT'
  },
  FOLLOW_UP_REQUIRED: {
    label: 'Follow up required',
    value: 'FOLLOW_UP_REQUIRED'
  },
  FOLLOW_UP_REQUIRED_WITH_CUSTOMER_ON_PREFERRED_SLOTS_WITH_NO_ACTION_FOR_24_HOURS:
    {
      label: 'Follow up on customer preferred slots - no action for 24 hours',
      value:
        'FOLLOW_UP_REQUIRED_WITH_CUSTOMER_ON_PREFERRED_SLOTS_WITH_NO_ACTION_FOR_24_HOURS'
    },
  FOLLOW_UP_REQUIRED_WITH_CUSTOMER_ON_PREFERRED_SLOT: {
    label: 'Follow up on customer preferred slots',
    value: 'FOLLOW_UP_REQUIRED_WITH_CUSTOMER_ON_PREFERRED_SLOT'
  },
  CLINIC_TIME_SLOT_SUBMITTED_CHECK_WITH_PATIENT_ON_CLINIC_SUGGESTED_SLOTS: {
    label: 'Check clinic slots with patient',
    value:
      'CLINIC_TIME_SLOT_SUBMITTED_CHECK_WITH_PATIENT_ON_CLINIC_SUGGESTED_SLOTS'
  },
  CLINIC_TIME_SLOT_EXPIRED_SETUP_APPOINTMENT_MANUALLY_WITH_PATIENT_AND_CLINIC: {
    label: 'Clinic slots expired',
    value:
      'CLINIC_TIME_SLOT_EXPIRED_SETUP_APPOINTMENT_MANUALLY_WITH_PATIENT_AND_CLINIC'
  },
  CLINIC_TIME_SLOT_REJECTED_SETUP_APPOINTMENT_MANUALLY_WITH_PATIENT_AND_CLINIC:
    {
      label: 'Clinic slots rejected',
      value:
        'CLINIC_TIME_SLOT_REJECTED_SETUP_APPOINTMENT_MANUALLY_WITH_PATIENT_AND_CLINIC'
    },
  FOLLOW_UP_REQUIRED_WITH_CLINIC_ON_SLOT_SUBMITTED_WITH_NO_ACTION_FOR_24_HOURS:
    {
      label: 'Follow up on clinic slots - no action for 24 hours',
      value:
        'FOLLOW_UP_REQUIRED_WITH_CLINIC_ON_SLOT_SUBMITTED_WITH_NO_ACTION_FOR_24_HOURS'
    }
};

export const TRACK_ACTION_REQUIRED: Record<string, string> = {
  SCHEDULE_APPOINTMENT: 'Schedule appointment',
  SCHEDULE_APPOINTMENT_SUBMITTED:
    'Schedule appointment (Availability submitted)',
  RESCHEDULE_APPOINTMENT: 'Reschedule appointment',
  CHECK_APPOINTMENT_ATTENDANCE: 'Check appointment attendance',
  REMIND_PATIENT_AND_CONFIRM_APPOINTMENT:
    'Remind patient and confirm appointment',
  NO_ACTION_REQUIRED: 'No action required'
};

export const EDITABLE_APPOINTMENT_STATUS_OPTIONS = [
  APPOINTMENT_STATUS_OPTIONS.BOOKED,
  APPOINTMENT_STATUS_OPTIONS.SHOW_UP,
  APPOINTMENT_STATUS_OPTIONS.NO_SHOW,
  APPOINTMENT_STATUS_OPTIONS.CANCELLED
];

const APPOINTMENT_OPTIONS = [
  {
    value: APPOINTMENT_STATUS_OPTIONS.BOOKED.value,
    label: APPOINTMENT_STATUS_OPTIONS.BOOKED.label,
    icon: (
      <span className='text-PRIMARY_DARK '>
        <BookedIcon />
      </span>
    ),
    className: 'text-PRIMARY_DARK'
  },
  {
    value: APPOINTMENT_STATUS_OPTIONS.SHOW_UP.value,
    label: APPOINTMENT_STATUS_OPTIONS.SHOW_UP.label,
    icon: (
      <span className='text-SUCCESS_TEXT'>
        <BookedIcon />
      </span>
    ),
    className: 'text-SUCCESS_TEXT'
  },
  {
    value: APPOINTMENT_STATUS_OPTIONS.CANCELLED.value,
    label: APPOINTMENT_STATUS_OPTIONS.CANCELLED.label,
    icon: (
      <span className='text-YELLOW_2'>
        <CancelledIcon />
      </span>
    ),
    className: 'text-YELLOW_2'
  },
  {
    value: APPOINTMENT_STATUS_OPTIONS.NO_SHOW.value,
    label: APPOINTMENT_STATUS_OPTIONS.NO_SHOW.label,
    icon: (
      <span className='text-ERROR w-4 h-4'>
        <ErrorCrossIcon />
      </span>
    ),
    className: 'text-ERROR'
  }
];

export const DEFAULT_APPT_DURATION = 45;

enum PREFERRED_CARD {
  DEFAULT,
  CLINIC,
  PATIENT,
  CLINIC_PATIENT
}

const CONFIRMED_APPOINTMENT_FILTERS = {
  doctors: {
    key: 'doctors',
    label: 'Doctors'
  },
  appointmentsDate: {
    key: 'appointmentsDate',
    label: 'Appointment date range'
  },
  dateConfirmed: {
    key: 'dateConfirmed',
    label: 'Date confirmed'
  },
  confirmedBy: {
    key: 'confirmedBy',
    label: 'Confirmed by'
  }
};

const STATUS_ACTION_MAPPER: Record<string, string> = {
  EXPIRED: 'Slot released',
  ACTIVE: 'Pending',
  REJECTED: 'Slot released'
};

const CONFIRMED_BY_MAPPER: Record<string, string> = {
  ZENYUM_CS: 'Zenyum CS',
  CLINIC: 'Clinic',
  AUTOMATED: 'Automated'
};

const CONFIRMED_BY_FILTERS = [
  {
    value: 'ZENYUM_CS',
    label: CONFIRMED_BY_MAPPER.ZENYUM_CS
  },
  {
    value: 'CLINIC',
    label: CONFIRMED_BY_MAPPER.CLINIC
  },
  {
    value: 'AUTOMATED',
    label: CONFIRMED_BY_MAPPER.AUTOMATED
  }
];

const CONFIRM_BUTTON_VALUES = {
  UPDATE: 'update',
  RESCHEDULE: 'reschedule'
};

const STATUS = {
  TO_BE_SCHEDULED: 'TO_BE_SCHEDULED',
  BOOKED: 'BOOKED',
  CONFIRMED: 'CONFIRMED',
  DISABLED: 'DISABLED',
  RESCHEDULED: 'RESCHEDULED',
  SHOW_UP: 'SHOW_UP',
  CANCELLED: 'CANCELLED',
  NO_SHOW: 'NO_SHOW'
};

const ATTENDANCE_OPTION = [
  { label: 'Show Up', value: STATUS.SHOW_UP },
  { label: 'No Show', value: STATUS.NO_SHOW }
];

const APPOINTMENT_TYPE: Record<string, string> = {
  EXTRACTION: 'after_sales_appointment_type.extraction',
  FITTING: 'after_sales_appointment_type.fitting',
  IPR: 'after_sales_appointment_type.ipr',
  SCAN: 'after_sales_appointment_type.scan',
  RE_SCAN: 'after_sales_appointment_type.rescan',
  RE_CHECK: 'after_sales_appointment_type.re_check',
  MID_REVIEW: 'after_sales_appointment_type.mid_review',
  FINAL_REVIEW: 'after_sales_appointment_type.end_review',
  CONSULTATION: 'CONSULTATION',
  ADD_ATTACHMENT: 'after_sales_appointment_type.add_attachment',
  REMOVE_ATTACHMENT: 'after_sales_appointment_type.remove_attachment',
  ADD_BUTTON: 'after_sales_appointment_type.add_button',
  REMOVE_BUTTON: 'after_sales_appointment_type.remove_button'
};

const TREATMENT_TYPE: Record<string, string> = {
  ZENCLEAR: 'prescriptions.zenyumclear',
  ZENCLEAR_PLUS: 'prescriptions.zenyumclear_plus'
};

const STATUS_TEXT: AfterSalesAppointmentActionBtns = {
  BOOKED: 'calendar.booked',
  TO_BE_SCHEDULED: 'after_sales_appointment_status.to_be_scheduled',
  DISABLED: 'after_sales_appointment_status.disabled',
  CANCELLED: 'calendar.cancelled',
  CONFIRMED: 'after_sales_appointment_status.confirmed',
  SHOW_UP: 'after_sales_appointment_status.show_up',
  NO_SHOW: 'calendar.no_show',
  RESCHEDULED: 'calendar.rescheduled',
  CONFIRMED_BY_PATIENT_AND_CLINIC: 'after_sales_appointment_status.confirmed'
};

const TRACK_APPT_STATUS: Record<string, string> = {
  TO_BE_SCHEDULED: 'To be scheduled',
  BOOKED: 'Booked',
  CONFIRMED: 'Confirmed',
  RESCHEDULED: 'Rescheduled',
  CANCELLED: 'Cancelled',
  NO_SHOW: 'No show',
  SHOW_UP: 'Showed up'
};

export const TRACK_APPOINTMENT_TYPE: Record<string, string> = {
  EXTRACTION: 'Extraction',
  FITTING: 'Fitting',
  IPR: 'IPR',
  RE_SCAN: 'Re-scan',
  MID_REVIEW: 'Mid-treatment review',
  FINAL_REVIEW: 'End-of-treatment review'
};

const AFTER_SALES_ACTIONS = {
  CANCEL: 'CANCEL',
  RESCHEDULE: 'RESCHEDULE',
  EDIT: 'EDIT',
  CONFIRM: 'CONFIRM',
  SCHEDULE: 'SCHEDULE',
  DISABLE: 'DISABLE',
  ENABLE: 'ENABLE',
  ATTENDANCE: 'ATTENDANCE',
  SHOW_UP: 'SHOW_UP',
  NO_SHOW: 'NO_SHOW',
  OUTCOME: 'OUTCOME',
  DELETE: 'DELETE'
};

const ACTION_REQUIRED = {
  SCHEDULE_APPOINTMENT: 'SCHEDULE_APPOINTMENT',
  SCHEDULE_APPOINTMENT_SUBMITTED: 'SCHEDULE_APPOINTMENT_SUBMITTED',
  RESCHEDULE_APPOINTMENT: 'RESCHEDULE_APPOINTMENT',
  REMIND_PATIENT_AND_CONFIRM_APPOINTMENT:
    'REMIND_PATIENT_AND_CONFIRM_APPOINTMENT',
  CHECK_APPOINTMENT_ATTENDANCE: 'CHECK_APPOINTMENT_ATTENDANCE',
  NO_ACTION_REQUIRED: 'NO_ACTION_REQUIRED'
};

const AFTER_SALES_APPOINTMENT_ACTION_BTN: Record<
  string,
  Record<string, string[]>
> = {
  [STATUS.TO_BE_SCHEDULED]: {
    [ACTION_REQUIRED.SCHEDULE_APPOINTMENT]: ['SCHEDULE', 'DISABLE'],
    [ACTION_REQUIRED.SCHEDULE_APPOINTMENT_SUBMITTED]: ['SCHEDULE', 'DISABLE'],
    [ACTION_REQUIRED.NO_ACTION_REQUIRED]: ['SCHEDULE', 'DISABLE']
  },
  [STATUS.BOOKED]: {
    [ACTION_REQUIRED.NO_ACTION_REQUIRED]: ['CANCEL', 'RESCHEDULE', 'EDIT'],
    [ACTION_REQUIRED.REMIND_PATIENT_AND_CONFIRM_APPOINTMENT]: [
      'CANCEL',
      'RESCHEDULE',
      'EDIT',
      'CONFIRM'
    ],
    [ACTION_REQUIRED.CHECK_APPOINTMENT_ATTENDANCE]: ['ATTENDANCE', 'EDIT']
  },
  [STATUS.RESCHEDULED]: {
    [ACTION_REQUIRED.RESCHEDULE_APPOINTMENT]: ['CANCEL', 'RESCHEDULE'],
    [ACTION_REQUIRED.NO_ACTION_REQUIRED]: []
  },
  [STATUS.CONFIRMED]: {
    [ACTION_REQUIRED.CHECK_APPOINTMENT_ATTENDANCE]: ['ATTENDANCE', 'EDIT'],
    [ACTION_REQUIRED.NO_ACTION_REQUIRED]: ['CANCEL', 'RESCHEDULE', 'EDIT']
  },
  [STATUS.NO_SHOW]: {
    [ACTION_REQUIRED.RESCHEDULE_APPOINTMENT]: [
      'CANCEL',
      'RESCHEDULE',
      'ATTENDANCE'
    ],
    [ACTION_REQUIRED.NO_ACTION_REQUIRED]: []
  },
  [STATUS.SHOW_UP]: { [ACTION_REQUIRED.NO_ACTION_REQUIRED]: ['ATTENDANCE'] },
  [STATUS.CANCELLED]: { [ACTION_REQUIRED.NO_ACTION_REQUIRED]: ['RESCHEDULE'] },
  [STATUS.DISABLED]: { [ACTION_REQUIRED.NO_ACTION_REQUIRED]: ['ENABLE'] }
};

const AFTER_SALES_APPOINTMENT_ACTION_LABEL: AfterSalesAppointmentActionBtns = {
  [AFTER_SALES_ACTIONS.CANCEL]: 'cancel.cancel',
  [AFTER_SALES_ACTIONS.RESCHEDULE]: 'calendar.reschedule',
  [AFTER_SALES_ACTIONS.EDIT]: 'edit.edit',
  [AFTER_SALES_ACTIONS.CONFIRM]: 'confirm.confirm',
  [AFTER_SALES_ACTIONS.SCHEDULE]: 'schedule.schedule',
  [AFTER_SALES_ACTIONS.DISABLE]: 'edit_appointment.disable',
  [AFTER_SALES_ACTIONS.ENABLE]: 'edit_appointment.enable',
  [AFTER_SALES_ACTIONS.ATTENDANCE]: 'aftersales_appointment.check_appointment',
  [AFTER_SALES_ACTIONS.OUTCOME]: 'aftersales_appointment.appointment_outcome',
  [AFTER_SALES_ACTIONS.SHOW_UP]: 'after_sales_appointment_status.show_up',
  [AFTER_SALES_ACTIONS.NO_SHOW]: 'calendar.no_show'
};

const AFTERSALES_APPOINTMENT_POPUP_MSGS: AfterSalesAppointmentActionBtns = {
  CANCEL: 'after_sales_appointment_popup.cancel',
  RESCHEDULE: 'after_sales_appointment_popup.reschedule',
  EDIT: 'after_sales_appointment_popup.edit',
  CONFIRM: 'after_sales_appointment_popup.confirm',
  SCHEDULE: 'after_sales_appointment_popup.schedule',
  DISABLE: 'after_sales_appointment_popup.disable',
  ENABLE: 'after_sales_appointment_popup.enable',
  ATTENDANCE: 'aftersales_appointment.check_appointment',
  SHOW_UP: 'after_sales_appointment_popup.showup',
  NO_SHOW: 'after_sales_appointment_popup.noshow',
  ADD_APPOINTMENT: 'after_sales_appointment_popup.addappointment',
  DELETE: 'after_sales_appointment_popup.delete'
};

const APPOINTMENT_ACTION_TOOLTIP = {
  [ACTION_REQUIRED.SCHEDULE_APPOINTMENT]: {
    message: 'aftersales_appointment.schedule_appointment',
    tooltip: 'edit_appointment.action_needed_by_customer_care'
  },
  [ACTION_REQUIRED.REMIND_PATIENT_AND_CONFIRM_APPOINTMENT]: {
    message: 'edit_appointment.confirm_appointment',
    tooltip: 'edit_appointment.action_needed_by_customer_care'
  },
  [ACTION_REQUIRED.CHECK_APPOINTMENT_ATTENDANCE]: {
    message: 'aftersales_appointment.check_appointment',
    tooltip: 'edit_appointment.action_needed_by_customer_care'
  },
  [ACTION_REQUIRED.RESCHEDULE_APPOINTMENT]: {
    message: 'after_sales_appointment_actions.reschedule_appointment',
    tooltip: 'edit_appointment.action_needed_by_customer_care'
  }
};
const CONFIRMATION_MODAL_WIDTH = 'w-1058px'; //used in aftersalesPopup and overlay of after-sales confirmation popup

const ACTION_REQUIRED_TEXT: Record<string, string> = {
  [ACTION_REQUIRED.SCHEDULE_APPOINTMENT]:
    'aftersales_appointment.schedule_appointment',
  [ACTION_REQUIRED.SCHEDULE_APPOINTMENT_SUBMITTED]:
    'after_sales_appointment_actions.schedule_appointment_availability',
  [ACTION_REQUIRED.RESCHEDULE_APPOINTMENT]:
    'after_sales_appointment_actions.reschedule_appointment',
  [ACTION_REQUIRED.REMIND_PATIENT_AND_CONFIRM_APPOINTMENT]:
    'after_sales_appointment_actions.remind_patient',
  [ACTION_REQUIRED.CHECK_APPOINTMENT_ATTENDANCE]:
    'aftersales_appointment.check_appointment',
  [ACTION_REQUIRED.NO_ACTION_REQUIRED]:
    'after_sales_appointment_actions.no_action'
};

const AFTER_SALES_APPOINTMENT_STATUS_OPTIONS: Record<string, Option> = {
  SCHEDULE_APPOINTMENT: {
    label: translate('aftersales_appointment.schedule_appointment'),
    value: ACTION_REQUIRED.SCHEDULE_APPOINTMENT
  },
  SCHEDULE_APPOINTMENT_AVAILABILITY_SUBMITTED: {
    label: translate(
      'after_sales_appointment_actions.schedule_appointment_availability'
    ),
    value: ACTION_REQUIRED.SCHEDULE_APPOINTMENT_SUBMITTED
  },
  RESCHEDULE_APPOINTMENT: {
    label: translate('after_sales_appointment_actions.reschedule_appointment'),
    value: ACTION_REQUIRED.RESCHEDULE_APPOINTMENT
  },
  REMIND_PATIENT_AND_CONFIRM_APPOINTMENT: {
    label: translate('after_sales_appointment_actions.remind_patient'),
    value: ACTION_REQUIRED.REMIND_PATIENT_AND_CONFIRM_APPOINTMENT
  },
  CHECK_APPOINTMENT_ATTENDANCE: {
    label: translate('aftersales_appointment.check_appointment'),
    value: ACTION_REQUIRED.CHECK_APPOINTMENT_ATTENDANCE
  },
  NO_ACTION_REQUIRED: {
    label: translate('after_sales_appointment_actions.no_action'),
    value: ACTION_REQUIRED.NO_ACTION_REQUIRED
  }
};

const DEFAULT_NOTICE_PERIOD_VALUE = 30;

const MAX_NOTICE_PERIOD_VALUE = 90;

const ERROR_MESSAGES = {
  START_TIME_AFTER_END_TIME: 'Start time is after end time. Please revise',
  START_TIME_GREATER_THAN_CURRENT_TIME:
    'Start should be greater than current time'
};

const PAGE_TABS = {
  AFTERSALES_APPOINTMENT: 'Aftersales Appointment',
  SIDE_PANEL: 'Side Panel',
  CALENDAR: 'Calendar',
  NONE: 'none'
};

const DEFAULT_ADDITIONAL_INFO_VALUE = {
  advanceNoticePeriod: DEFAULT_NOTICE_PERIOD_VALUE,
  comments: 'Patient is busy on weekend'
};

const APPT_STATUS_LIST = [
  { value: 'TO_BE_SCHEDULED', label: STATUS_TEXT.TO_BE_SCHEDULED },
  { value: 'BOOKED', label: STATUS_TEXT.BOOKED },
  { value: 'CONFIRMED', label: STATUS_TEXT.CONFIRMED },
  { value: 'RESCHEDULED', label: STATUS_TEXT.RESCHEDULED },
  { value: 'SHOW_UP', label: STATUS_TEXT.SHOW_UP },
  { value: 'NO_SHOW', label: STATUS_TEXT.NO_SHOW },
  { value: 'CANCELLED', label: STATUS_TEXT.CANCELLED }
];

const APPT_TYPE_OPTION_VALUE = {
  FITTING: 'FITTING',
  MID_REVIEW: 'MID_REVIEW',
  FINAL_REVIEW: 'FINAL_REVIEW',
  IPR: 'IPR',
  EXTRACTION: 'EXTRACTION',
  RE_SCAN: 'RE_SCAN',
  ADD_ATTACHMENT: 'ADD_ATTACHMENT',
  REMOVE_ATTACHMENT: 'REMOVE_ATTACHMENT',
  ADD_BUTTON: 'ADD_BUTTON',
  REMOVE_BUTTON: 'REMOVE_BUTTON',
  SCAN: 'SCAN',
  RE_CHECK: 'RE_CHECK',
  CONSULTATION: 'CONSULTATION'
};

const APPT_TYPE = [
  { value: APPT_TYPE_OPTION_VALUE.FITTING, label: APPOINTMENT_TYPE.FITTING },
  {
    value: APPT_TYPE_OPTION_VALUE.MID_REVIEW,
    label: APPOINTMENT_TYPE.MID_REVIEW
  },
  {
    value: APPT_TYPE_OPTION_VALUE.FINAL_REVIEW,
    label: APPOINTMENT_TYPE.FINAL_REVIEW
  },
  { value: APPT_TYPE_OPTION_VALUE.IPR, label: APPOINTMENT_TYPE.IPR },
  { value: APPT_TYPE_OPTION_VALUE.SCAN, label: APPOINTMENT_TYPE.SCAN },
  { value: APPT_TYPE_OPTION_VALUE.RE_SCAN, label: APPOINTMENT_TYPE.RE_SCAN },
  { value: APPT_TYPE_OPTION_VALUE.RE_CHECK, label: APPOINTMENT_TYPE.RE_CHECK },
  {
    value: APPT_TYPE_OPTION_VALUE.EXTRACTION,
    label: APPOINTMENT_TYPE.EXTRACTION
  },
  {
    value: APPT_TYPE_OPTION_VALUE.ADD_ATTACHMENT,
    label: APPOINTMENT_TYPE.ADD_ATTACHMENT
  },
  {
    value: APPT_TYPE_OPTION_VALUE.REMOVE_ATTACHMENT,
    label: APPOINTMENT_TYPE.REMOVE_ATTACHMENT
  },
  {
    value: APPT_TYPE_OPTION_VALUE.ADD_BUTTON,
    label: APPOINTMENT_TYPE.ADD_BUTTON
  },
  {
    value: APPT_TYPE_OPTION_VALUE.REMOVE_BUTTON,
    label: APPOINTMENT_TYPE.REMOVE_BUTTON
  }
];

const RETAINER_APPT_TYPES = [
  {
    value: APPT_TYPE_OPTION_VALUE.SCAN,
    label: APPOINTMENT_TYPE.SCAN
  },
  { value: APPT_TYPE_OPTION_VALUE.FITTING, label: APPOINTMENT_TYPE.FITTING }
];

const APPOINTMENT_STATE: Record<string, string> = {
  ENABLE: 'aftersales_appointment.enabled',
  DISABLE: 'after_sales_appointment_status.disabled'
};

const APPT_STATE = [
  { value: 'enable', label: APPOINTMENT_STATE.ENABLE },
  { value: 'disable', label: APPOINTMENT_STATE.DISABLE }
];

const APPT_ACTION_REQUIRED: Option[] = Object.values(
  AFTER_SALES_APPOINTMENT_STATUS_OPTIONS
);

const APPOINTMENT_ORDER_TEXT: Record<string, string> = {
  BEFORE_ALIGNER: 'sidepanel_appointmentlist.before',
  AFTER_ALIGNER: 'sidepanel_appointmentlist.after'
};

const FILTER_CARD_STATUS: Record<string, string> = {
  ENABLED: 'Enabled',
  DISABLED: 'Disabled'
};

const FILTER_CARD_TASK_NAME: Record<string, string> = {
  CONFIRM: 'Confirm appointment with patient',
  SCHEDULE: 'Schedule appointment',
  CHECK: 'Check appointment attendance'
};

export const SEGMENT_CARD_FILTER: Record<string, string> = {
  Confirm: FILTER_CARD_TASK_NAME.CONFIRM,
  Schedule: FILTER_CARD_TASK_NAME.SCHEDULE,
  Check: FILTER_CARD_TASK_NAME.CHECK
};

export enum CARD_FILTER_KEY {
  SCHEDULE = 'Schedule',
  CONFIRM = 'Confirm',
  CHECK = 'Check',
  NONE = 'None'
}

export const CARD_FILTER_OPTIONS: Record<CARD_FILTER_KEY, Option[]> = {
  [CARD_FILTER_KEY.SCHEDULE]: [
    {
      label: 'Schedule Appointment',
      value: 'SCHEDULE_APPOINTMENT'
    },
    {
      label: 'Schedule Appointment (Availability submitted)',
      value: 'SCHEDULE_APPOINTMENT_SUBMITTED'
    },
    {
      label: 'Reschedule Appointment',
      value: 'RESCHEDULE_APPOINTMENT'
    }
  ],
  [CARD_FILTER_KEY.CONFIRM]: [
    {
      label: 'Remind patient and confirm appointment',
      value: 'REMIND_PATIENT_AND_CONFIRM_APPOINTMENT'
    }
  ],
  [CARD_FILTER_KEY.CHECK]: [
    {
      label: 'Check appointment attendance',
      value: 'CHECK_APPOINTMENT_ATTENDANCE'
    }
  ],
  [CARD_FILTER_KEY.NONE]: []
};

const ACTION_TYPE = {
  [AFTER_SALES_ACTIONS.SCHEDULE]: 'Schedule',
  [AFTER_SALES_ACTIONS.DISABLE]: 'Disable',
  [AFTER_SALES_ACTIONS.ENABLE]: 'Enable',
  [AFTER_SALES_ACTIONS.CANCEL]: 'Cancel',
  [AFTER_SALES_ACTIONS.RESCHEDULE]: 'Reschedule',
  [AFTER_SALES_ACTIONS.EDIT]: 'Edit',
  [AFTER_SALES_ACTIONS.CONFIRM]: 'Confirm',
  [AFTER_SALES_ACTIONS.ATTENDANCE]: 'Check appt attendance',
  [AFTER_SALES_ACTIONS.DELETE]: 'Delete'
};

export const SEGMENT_PAGE = {
  AFTER_SALES: 'Aftersales Appointment',
  SIDE_PANEL: 'Side Panel'
};

export const SEGMENT_PAGE_TAB = {
  AFTER_SALES: 'Aftersales Appointment',
  PRE_SALES: 'Presales Appointment'
};

const NONE_OPTION = {
  value: 'NONE',
  label: '(Blanks)'
};

const AFTER_SALES_PATIENT_FILTER = {
  AFTERSALES: 'AFTERSALES',
  REFINEMENT: 'REFINEMENT'
};

const PREFERENCE_APPT_TYPE_MAPPER: Record<string, string> = {
  IPR: 'STAGE_IPR',
  RE_SCAN: 'RESCAN',
  FITTING: 'FITTING',
  FINAL_REVIEW: 'REVIEW',
  MID_REVIEW: 'REVIEW'
};

const TIME_RANGE_PICKER = {
  START_TIME: 'START_TIME',
  END_TIME: 'END_TIME'
};

const DAYS: Record<number, string> = {
  0: 'SUNDAY',
  1: 'MONDAY',
  2: 'TUESDAY',
  3: 'WEDNESDAY',
  4: 'THURSDAY',
  5: 'FRIDAY',
  6: 'SATURDAY'
};

const EPECTED_DATE_FIELD_NAME = 'expectedDate';

const APPOINTMENT_STAGE = {
  CONFIRMED_APPOINTMENTS: 'CONFIRMED_APPOINTMENTS',
  INCOMING_APPOINTMENTS: 'INCOMING_APPOINTMENTS'
};

export {
  STATUS,
  ACTION_TYPE,
  NONE_OPTION,
  PREFERRED_CARD,
  APPOINTMENT_TABS,
  TIME_RANGE_PICKER,
  TRACK_APPT_STATUS,
  CONFIRMED_BY_MAPPER,
  APPOINTMENT_TYPE,
  APPOINTMENT_OPTIONS,
  STATUS_ACTION_MAPPER,
  CONFIRMED_BY_FILTERS,
  CONFIRM_BUTTON_VALUES,
  APPOINTMENT_TAB_OPTIONS,
  CONFIRMED_APPOINTMENT_FILTERS,
  AFTER_SALES_APPOINTMENT_ACTION_BTN,
  AFTER_SALES_APPOINTMENT_ACTION_LABEL,
  CONFIRMATION_MODAL_WIDTH,
  APPOINTMENT_ACTION_TOOLTIP,
  AFTER_SALES_APPOINTMENT_STATUS_OPTIONS,
  STATUS_TEXT,
  ATTENDANCE_OPTION,
  AFTERSALES_APPOINTMENT_POPUP_MSGS,
  DEFAULT_ADDITIONAL_INFO_VALUE,
  AFTER_SALES_ACTIONS,
  DEFAULT_NOTICE_PERIOD_VALUE,
  MAX_NOTICE_PERIOD_VALUE,
  APPT_STATUS_LIST,
  APPT_TYPE,
  APPT_ACTION_REQUIRED,
  APPOINTMENT_ORDER_TEXT,
  APPT_STATE,
  ACTION_REQUIRED_TEXT,
  FILTER_CARD_STATUS,
  FILTER_CARD_TASK_NAME,
  ERROR_MESSAGES,
  PAGE_TABS,
  ACTION_REQUIRED,
  AFTER_SALES_PATIENT_FILTER,
  APPT_TYPE_OPTION_VALUE,
  PREFERENCE_APPT_TYPE_MAPPER,
  TREATMENT_TYPE,
  DAYS,
  EPECTED_DATE_FIELD_NAME,
  APPOINTMENT_STAGE,
  RETAINER_APPT_TYPES
};

import { PATIENT_LIST_FILTERS } from 'types/PatientList.types';

export const CREATE_TPV = 'createTPV';
export const CONSULTATION_APPOINTMENT_BOOKED_STATUS =
  'status.consultation_appointment_overdue';
export const PENDING_PRESCRIPTION_SUBMISSION =
  'PENDING_PRESCRIPTION_SUBMISSION';
export const CONSULTATION_APPOINTMENT_OVERDUE =
  'CONSULTATION_APPOINTMENT_OVERDUE';

export const TREATMENT_TYPE = {
  AFTERSALES: 'AFTERSALES',
  BRACES: 'BRACES',
  REFINEMENT: 'REFINEMENT',
  RETAINER: 'RETAINER',
  WHITENING: 'WHITENING',
  ZENYUM_RETAINER: 'ZENYUM_RETAINER'
};

export const RETAINER_STAGE = {
  PURCHASE: 'PURCHASE',
  PRESCRIPTION: 'PRESCRIPTION',
  PRODUCTION_AND_DELIVERY: 'PRODUCTION_AND_DELIVERY',
  TREATMENT: 'TREATMENT'
};

export const WHITENING_STAGE = {
  PURCHASE: 'PURCHASE',
  PRESCRIPTION: 'PRESCRIPTION',
  DELIVERY: 'DELIVERY'
};

export const TREATMENT_STAGES: Record<string, string> = {
  PENDING_RESUBMISSION: 'Pending Resubmission',
  PRE_ASSESSMENT: 'PRE_ASSESSMENT',
  ALL_ALIGNERS: 'All aligners',
  PENDING_PRESCRIPTION: 'Pending prescription',
  TREATMENT_PLAN_REVIEW: 'Treatment Plan Review',
  IN_TREATMENT: 'In treatment',
  END_OF_TREATMENT_REVIEW: 'End of treatment',
  INITIAL_TPV: 'Initial tpv',
  RESIM_REQUESTS: 'Resim requests',
  TPV_REVIEW: 'TPV Review',
  ALL_RETAINERS: 'All retainers',
  ALL_WHITENING: 'All whitenings'
};
export const AppointmentTypesMap: Record<string, string> = {
  REVIEW: 'REVIEW',
  FINAL_REVIEW: 'stage.EndofTreatment',
  FITTING: 'after_sales_appointment_type.fitting',
  CONSULTATION: 'CONSULTATION'
};
export const DELIVERY_DETAILS_STATUSES = [
  'Production',
  'Warehouse',
  'Dispatched',
  'In Production',
  'Last Mile Delivery'
];
export const DELIVERY_OBJECT_DETAILS: Record<
  string,
  {
    position: number;
    type: string;
    key: string;
    label: string;
    count: number;
  }
> = {
  Production: {
    position: 0,
    type: 'Aligner',
    key: 'Production',
    label: 'Production.Production',
    count: 0
  },
  Warehouse: {
    position: 1,
    type: 'Aligner',
    key: 'Warehouse',
    label: 'status.warehoue',
    count: 0
  },
  Dispatched: {
    position: 2,
    type: 'Aligner',
    key: 'Dispatched',
    label: 'deliveryInfo.Dispatched',
    count: 0
  },
  'In Production': {
    position: 0,
    type: 'Retainer',
    key: 'In Production',
    label: 'Production.Production',
    count: 0
  },
  'Last Mile Delivery': {
    position: 1,
    type: 'Retainer',
    key: 'Last Mile Delivery',
    label: 'deliveryInfo.Dispatched',
    count: 0
  }
};
export const RETAINER_STATUSES: Record<string, string> = {
  PENDING_PRESCRIPTION: 'PENDING_PRESCRIPTION',
  RESUBMIT_SCAN: 'RESUBMIT_SCAN',
  DELIVERED: 'DELIVERED'
};

export const ALL_RETAINERS = 'All retainers';

export const REFERRAL_OPTIONS = [
  { value: 'true', label: 'referrals.referrals' },
  {
    value: '',
    label: 'non-referrals.non-referrals'
  }
];
export const OTHER_TASKS = [
  TREATMENT_STAGES.INITIAL_TPV,
  TREATMENT_STAGES.RESIM_REQUESTS,
  TREATMENT_STAGES.TPV_REVIEW,
  TREATMENT_STAGES.ALL_ALIGNERS,
  TREATMENT_STAGES.PRE_ASSESSMENT,
  TREATMENT_STAGES.IN_TREATMENT,
  ALL_RETAINERS
];

export const RETAINER_STATUS = {
  PURCHASED: 'PURCHASED',
  IN_PRODUCTION: 'IN_PRODUCTION',
  LAST_MILE_DELIVERY: 'LAST_MILE_DELIVERY',
  DELIVERED: 'DELIVERED',
  ISSUED_TO_PATIENT: 'ISSUED_TO_PATIENT',
  RESUBMIT_SCAN: 'RESUBMIT_SCAN'
};

export const RETAINER_STATUS_KEYS = {
  PURCHASED: 'status.purchased',
  IN_PRODUCTION: 'status.in_production',
  LAST_MILE_DELIVERY: 'status.last_mile_delivery',
  DELIVERED: 'status.delivered',
  ISSUED_TO_PATIENT: 'status.issued_to_patient',
  RESUBMIT_SCAN: 'status.resubmit_scan'
};

export const RETAINER_TASKS = [
  'PENDING_PRESCRIPTION',
  'RESUBMIT_SCAN',
  'DELIVERED'
];

export const RETAINER_TASKS_NAME: Record<string, string> = {
  DELIVERED: 'dashBoard.tasks.Delivered',
  PENDING_PRESCRIPTION: 'dashBoard.tasks.pendingPrescriptions',
  RESUBMIT_SCAN: 'dashBoard.tasks.resubmit'
};
export const PATIENT_LIST_FILTER_STORAGE_KEY = 'patientListFilters';
export const TABS_FILTER_LIST = {
  GIVE_INSTRUCTIONS: [
    PATIENT_LIST_FILTERS.PLAN_TYPE,
    PATIENT_LIST_FILTERS.COUNCIL,
    PATIENT_LIST_FILTERS.COUNTRY,
    PATIENT_LIST_FILTERS.BRANCHES,
    PATIENT_LIST_FILTERS.TREATMENT,
    PATIENT_LIST_FILTERS.DOCTOR
  ],
  TPV_REVIEW: [
    PATIENT_LIST_FILTERS.PLAN_TYPE,
    PATIENT_LIST_FILTERS.COUNCIL,
    PATIENT_LIST_FILTERS.COUNTRY,
    PATIENT_LIST_FILTERS.BRANCHES,
    PATIENT_LIST_FILTERS.DOCTOR,
    PATIENT_LIST_FILTERS.TREATMENT
  ],
  ALL_ALIGNERS: [
    PATIENT_LIST_FILTERS.REFERRAL,
    PATIENT_LIST_FILTERS.TYPE,
    PATIENT_LIST_FILTERS.STATUS,
    PATIENT_LIST_FILTERS?.BRANCHES,
    PATIENT_LIST_FILTERS.DOCTOR,
    PATIENT_LIST_FILTERS.SORT,
    PATIENT_LIST_FILTERS.TREATMENT
  ],
  ALL_RETAINERS: [
    PATIENT_LIST_FILTERS.RETAINER_STATUS,
    PATIENT_LIST_FILTERS?.BRANCHES,
    PATIENT_LIST_FILTERS.DOCTOR,
    PATIENT_LIST_FILTERS.SORT,
    PATIENT_LIST_FILTERS.ACTION_REQUIRED
  ],
  ALL_WHITENING: [
    PATIENT_LIST_FILTERS.WHITENING_STATUS,
    PATIENT_LIST_FILTERS.SORT,
    PATIENT_LIST_FILTERS.WHITENING_ACTIONS
  ]
};

export const CASE_OPS_CONSTANTS = {
  Case_Ops_Input: 'CASE_OPS_INPUT',
  Process_Case_CaseOps: 'WAITING_DESIGN_FILES'
};
export const HEADER_TEST_ID_MAPPER: Record<string, string> = {
  'tab.all_patients': 'header-all-patients',
  'tab.pending_prescription': 'header-pending-prescriptions',
  'tab.treatment_plan_review': 'header-treatment-plan-review',
  'tab.in_treatment': 'header-in-treatment',
  'tab.end_of_treatment': 'header-end-of-treatment-reviews',
  'tab.initial_tpv': 'header-initial-tpv',
  'tab.resim_requests': 'header-resim-requests'
};
export const REFINEMENT = 'REFINEMENT';
export const TREATMENT_PLAN_VERSION = 'TREATMENT_PLAN_VERSION';
export const RESOLVE_CASE_OPS_ISSUE = 'RESOLVE_CASE_OPS_ISSUE';
export const IS_FIRST_TIME_KEY = 'firstTimeToPatientPage';
export const INSTRUCTION_MAX_LENGTH = 2000;

export const planTypeOptions = [
  {
    value: 'ZENCLEAR',
    label: 'prescriptions.zenyumclear'
  },
  {
    value: 'ZENCLEAR_PLUS',
    label: 'prescriptions.zenyumclear_plus'
  },
  {
    value: 'BOTH',
    label: 'prescriptions.both'
  }
];
export const treatmentOptions = [
  {
    value: 'PRIMARY',
    label: 'sidepanel_appointmentlist.primary'
  },
  {
    value: 'REFINEMENT',
    label: 'refinement.refinement'
  }
];

export const PRODUCT_TABS = {
  ALIGNER: {
    key: 'aligner',
    value: 'Aligner'
  },
  RETAINER: {
    key: 'retainer',
    value: 'Retainer'
  },
  WHITENING: {
    key: 'whitening',
    value: 'Whitening'
  }
};

export const TREATMENT_FAMILY_GROUPS = {
  ALIGNER: 'aligner',
  RETAINER: 'retainer',
  WHITENING: 'whitening'
};

export const NON_PREDECESSOR_TREATMENTS = [
  TREATMENT_TYPE.BRACES,
  TREATMENT_TYPE.RETAINER,
  TREATMENT_TYPE.ZENYUM_RETAINER,
  TREATMENT_TYPE.WHITENING
];

import { gql } from '@apollo/client';

export const GET_OPPORTUNITY_ID = gql`
  query getOpportunityId($treatmentId: ID!) {
    getTreatmentById(id: $treatmentId) {
      id
      details {
        opportunityId
      }
    }
  }
`;

export const GET_TPV_DATA = gql`
  query getTPVData($treatmentId: ID!) {
    getTreatmentById(id: $treatmentId) {
      id
      predecessorId
      stage
      updatedAt
      treatmentAppointments(
        input: { appointmentType: "Consultation", limit: 1 }
      ) {
        id
        appointmentType
        patientTreatmentInfo {
          patientId
          caseComplexity
          patient {
            firstName
            lastName
          }
        }
        patientPreferredSlots {
          createdAt
        }
        createdAt
        statusInfo {
          statusCode
          actionCode
        }
      }
      clinic {
        id
        name
        country
        scannerType
        headClinicId
      }
      details {
        metadata
        opportunityId
        segmentInfo {
          prescriptionLastFilled {
            fieldName
            updatedAt
          }
          prescriptionFirstFilled {
            fieldName
            updatedAt
          }
        }
      }
      patient {
        id
        firstName
        lastName
        phone
        email
        profilePicture
        salutation
        details {
          ageCategory
        }
      }
      family {
        id
        type
        hasCaseOpsIssue
      }
      status
      employee {
        firstName
        lastName
      }
      documents {
        documentId
        documentName
        documentType
        classification
        sourceType
        presignedUrl
        extension
        origin
      }
      medicalStaff {
        doctors {
          primary
          role
          details {
            id
            firstName
            lastName
            details {
              maxComplexity
            }
          }
        }
      }
      treatmentSpecifications {
        teethToExtract
        iprLocation
        treatmentPlanInstructions
        zenplusInstructions
        treatmentPlanTypes
        crowdingResolvement
        attachmentRequired
        version
        tpv {
          archesToTreat
          toothMovementRestrictions
          prosthesis
          prosthesisOptions
          prosthesisIprToAvoid
          prosthesisAttachmentToAvoid
          teethToExtract
          specialNotes
          translatedSpecialNotes
          overbite
          midlines
          midlinesShifts
          treatmentGoals {
            name
            procedures
          }
          designId
          submissionReason
          alignerInfo
          refinementGoals
          attachments
          iprInfo
          iprInfoText
          totalAlignerSet
          attachmentsOnlyCheck
          addButtonsForElasticsCheck
          clinicalPreferences {
            iprSize
            iprLocation
            biteRamps
            premolarCrossbite
            extrusionElastics
          }
        }
      }
      priorTreatment {
        completed
        treatments
        canPlanningProceed
      }
      type
      treatmentPlan(input: { sort: { sortBy: "createdAt", sortOrder: DESC } }) {
        id
        status
        type
        versions(input: { sort: { sortBy: "createdAt", sortOrder: DESC } }) {
          id
          type
          treatmentPlanId
          acceptanceStatus
          name
          treatmentPlanningLab
          manufacturer
          details {
            prescription {
              treatmentGoals {
                name
                procedures
              }
              iprInfoText
              designId
              archesToTreat
              toothMovementRestrictions
              overbite
              midlines
              midlinesShifts
              teethToExtract
              prosthesis
              prosthesisIprToAvoid
              prosthesisAttachmentToAvoid
              specialNotes
              translatedSpecialNotes
              submissionReason
              refinementGoals
              attachments
              iprInfo
              alignerInfo
            }
          }
        }
      }
      keyLinks {
        salesforceLink
        adminPortalLink
      }
      terminated
    }
  }
`;

export const GET_TREATMENT_PLAN_VERSION_IPR_DATA = gql`
  query getTreatmentPlanVersionIPRData($input: TreatmentPlanVersionQueryInput) {
    getTreatmentPlanVersions(input: $input) {
      id
      urlSmileSummary
      treatmentPlan {
        type
      }
      details {
        designSpecification {
          ipr {
            setNumber
            position
            value
          }
        }
      }
      type
      treatmentPlanningLab
      manufacturer
    }
  }
`;
export const GET_DESIGN_LAB = gql`
  query getDesignLabs($input: GetDesignLabsInput!) {
    getDesignLabs(input: $input) {
      id
      isDefault
      treatmentPlanningLab
      manufacturer
    }
  }
`;

export const SAVE_TPV = gql`
  mutation SaveTPV($input: TreatmentPlanVersionCreateInput!) {
    saveTreatmentPlanVersion(input: $input) {
      id
      name
      acceptanceStatus
      treatmentPlanningLab
      details {
        prescription {
          specialNotes
          archesToTreat
          midlines
          midlinesShifts
          treatmentGoals {
            name
            procedures
          }
        }
      }
    }
  }
`;

export const SUBMIT_TPV = gql`
  mutation SubmitTPV($input: TreatmentPlanVersionCreateInput!) {
    submitTreatmentPlanVersion(input: $input) {
      id
      name
      acceptanceStatus
      details {
        prescription {
          specialNotes
          archesToTreat
          midlines
          midlinesShifts
          treatmentGoals {
            name
            procedures
          }
        }
      }
      treatmentPlan {
        type
        versions {
          name
        }
      }
    }
  }
`;

export const GET_SUPPORTED_DESIGN_LAB = gql`
  query getSupportedDesignLabs($input: GetSupportedDesignLabsInput!) {
    getSupportedDesignLabs(input: $input) {
      designLabs
      manufacturers
    }
  }
`;
